import "./Info.css";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const Info = () => {
  const position = [52.51, 13.38]

  return (
    <div className="Info" id="info">
        <h1 className="info-header">Info</h1>
      <div className="info-content">
        <h2><span>//</span>Juhlapaikka</h2>
        <p className="paragraph">
          Varastoconcept järjestää lauantaina 28.10.2023 Agroksenmäen Holvikellarissa Helsingin näyttävimmät Halloween bileet!
        </p>
        {/* Picture of casino */}
        {/* <img src="casino.jpg" alt="casino"> </img> */}
        <h2><span>//</span>Ajankohta</h2>
        <p className="paragraph">
          Ovet tapahtumaan avataan klo 21:00 ja juhlat kestävät klo 04:00 saakka. 
        </p> 
        <h2><span>//</span>More</h2>
        <p className="paragraph">
          Varastoconcept hoitaa tapahtuman anniskelun ensimmäistä kertaa itse ja takaa edulliset juomat koko illan ajaksi. Suosittelemme saapumaan paikalle ajoissa välttääksenne jonotuksen.

        </p> 
        {/*<h2>Ruoka</h2>
        <p className="paragraph">
          Älä huoli, sisältä Ääniwallista voit ostaa naposteltavaa!
        </p> */} 
        {/* <h2>TBA</h2> */}
        <div>
        {/* <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer> */}
      </div>
      </div>
    </div>
  );
};

export default Info;
