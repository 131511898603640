import { useState, useEffect } from "react";
import "./CountdownTimer.css";
import styled from "styled-components";

const CountdownTimerComponent = () => {
  useEffect(() => {
    const scrolls = document.getElementsByClassName("countdowntimercontainer");
    Array.from(scrolls).forEach((e) => {
      const childw = e.children[0].offsetWidth;
      const parentw = e.offsetWidth;

      let children = "";
      for (let i = 0; i < Math.ceil(parentw / childw) + 1; i++) {
        children += e.innerHTML;
      }

      e.innerHTML += children;
    });
  }, []);

  return (
    <div className="countdowntimercontainer">
      <div className="movingtimer">
        <CountdownTimer onComplete={() => console.log("done")} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ onComplete }) => {
  const isoStr = "2023-10-28T21:00:00.000Z";
  const endtime = ConvertIsoToDate(isoStr);
  // console.log(endtime);
  const time = GetTimeRemaining(endtime);
  // console.log(time);
  // console.log(time["total"] / 1000);
  const [seconds, setSeconds] = useState(time["total"] / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(interval);
      onComplete();
    }
    return () => clearInterval(interval);
  }, [seconds, onComplete]);
  return <div>{TurnSecondsIntoTime(seconds)}</div>;
  // return <div>TBA</div>;
};

export default CountdownTimerComponent;

const TurnSecondsIntoTime = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds / 3600) % 24);
  const minutes = Math.floor((seconds / 60) % 60);
  const s = Math.floor(seconds % 60);
  return `${days} days, ${hours} hours, ${minutes} minutes, ${s} seconds`;
};

const GetTimeRemaining = (endtime) => {
  const t = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days,
    hours,
    minutes,
    seconds,
  };
};

const ConvertIsoToDate = (iso) => {
  return new Date(iso);
};
