import "./About.css";

const About = () => {
  return (
    <div className="about">
      <h1>GALLERIA</h1>
      <div className="about-content">
        <a href="https://drive.google.com/drive/folders/1l66Yjobbg4SDjuE11bdV1eGgAJ6zCPXa?usp=drive_link">
          <h3 className="about-link">Summer 2023</h3>
        </a>
        <a href="https://photos.app.goo.gl/vdxgVnfbQ1kQpVm17">
          <h3 className="about-link">Winter 2023</h3>
        </a>
        <a href="https://drive.google.com/drive/folders/1_pk56y-yM0Muld7X2gWltV9JmbyEQFpV">
          <h3 className="about-link">Summer 2022</h3>
        </a>
        <a href="https://drive.google.com/drive/folders/1rOiTwk8-VUZGVLbZiXYYC2ORfv_BQzUJ">
          <h3 className="about-link">Summer 2022</h3>
        </a>
      </div>
    </div>
  );
};

export default About;
