import "./Ohjelma.css";
import { Aikataulut, SideImage, OhjelmaBoksi } from "./OhjelmaElements";
import MovingComponent from "react-moving-text";
import { useState, useEffect } from "react";

const Ohjelma = ({ index }) => {
  // const [currentIndex1, setCurrentIndex1] = useState(0);
  const images = [
    "aaro630.png",
    "vepu.png",
    "r5c.png",
    "aaro630.png",
    "vepu.png",
    "r5c.png",
  ];

  // const nextImage2 = () => {
  //   setCurrentImage2(currentImage2 + 1);

  //   if (currentImage2 === images.length - 1) {
  //     setCurrentImage2(0);
  //   }
  // };

  // useEffect(() => {
  //   const intervalId1 = setInterval(() => {
  //     if (currentIndex1 === images.length - 1) {
  //       setCurrentIndex1(0);
  //     } else {
  //       setCurrentIndex1(currentIndex1 + 1);
  //     }
  //   }, 5000);

  //   return () => {
  //     clearInterval(intervalId1);
  //   };
  // }, [currentIndex1]);

  return (
    <div className="ohjelma" id="ohjelma">
      <div className="ohjelma-kuva">
        <SideImage
          className="ohjelma-kuva-kuva"
          src={images[index]}
          alt="ohjelma"
        />
      </div>
      <OhjelmaBoksi className="ohjelma-tekstiboksi">
        <Aikataulut>
          {/* <MovingComponent
            type="fadeInFromRight"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
          > */}
          <h1>OHJELMA</h1>
          {/* </MovingComponent> */}
          <div className="ohjelma-content">
            {/* <h4 className="aikataulu-item">
                Mikael Laurent & FRIENDS (Mikael Laurent, LukaBo, Baby Blu, Serafiina & Kunta)
            </h4>
            <h4 className="aikataulu-item">
                Jaakko Kulta
            </h4>
            <h4 className="aikataulu-item">
                Jambo
            </h4>
            <h4 className="aikataulu-item">
                Turisti
                Aaro630
Vepu
andu!
somBadi
TBA
            </h4> */}
            <h3><span>//</span>Artistit</h3>
             <h4 className="aikataulu-item">
              Aaro630
            </h4>
            <h4 className="aikataulu-item">
              Vepu
            </h4>
            
            <h4 className="aikataulu-item">
              andu! 
            </h4>
            <h4 className="aikataulu-item">
              Sapphire
            </h4>
            <h4 className="aikataulu-item">
              DJ Hotleevibling
            </h4>
          </div>
          {/* <p1 className="aikataulu-item">KLO XX - SADADASDASD</p1>
          <p1 className="aikataulu-item">KLO XX - SADADASDASD</p1>
          <p1 className="aikataulu-item">KLO XX - SADADASDASD</p1>
          <p1 className="aikataulu-item">KLO XX - SADADASDASD</p1>
          <
          <p1 className="aikataulu-item">KLO XX - SADADASDASD</p1> */}
          {/* <a href="https://www.tiketti.fi/tapahtuma/87144" className="btn">
            Osta liput
          </a> */}
        </Aikataulut>
      </OhjelmaBoksi>
    </div>
  );
};

export default Ohjelma;
