import styled from "styled-components";
import { Link as Link } from "react-scroll";
import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  background: #110e10;
  height: 80px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition-duration: 0.5s;
  transition-property: height, background;
  &:hover {
    color: var(--dark);
    font-style: italic;
    font-weight: bold;
    background: var(--orange);
    transition: ease-in;
  }
  &.active {
    color: #74a8e1;
    font-style: italic;
    border-bottom: 2px solid #110e10;
    background: #fff;
  }
`;

// export const Bars = styled(FaBars)`
//   display: none;
//   color: #fcebd7;

//   @media screen and (max-width: 768px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
// `;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.div`
  display: flex;
  margin-right: 24px;
  background: #110e10;
  align-items: center;
  @media screen and (max-width: 768px) {
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background:  var(--orange);
  padding: 10px 22px;
  color: var(--black);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-radius: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: var(--orange);
  }
`;
