import { useState, useEffect } from "react";

const mobileImageStyle = {
  maxHeight: "40vh",
  width: "100%",
};

const MobileImage = ({ index }) => {
  
  const mobileImages = [
    "aaromobile.png",
    "vepumobile.png",
    "kellari.jpg",
  "aaromobile.png",
    "vepumobile.png",
    "kellari.jpg",
  ]
  if (index > 5) {
    index = 0;
  }
  return (
    <div className="mobile-image">
      <img src={mobileImages[index]} alt="mobile-image" style={mobileImageStyle} />
    </div>
  );
};

export default MobileImage;
