import { SideImage } from "../Galleria/GalleriaElements";
import { GalleriaBoksi } from "./GalleriaElements";
import "./Galleria.css";
// import MovingComponent from "react-moving-text";
import { useEffect, useState } from "react";

const Galleria = ({ index }) => {
  // const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    "UntitledJaakko2.png",
    "kellari1.png",
    "UntitledJambo2.png",
    "kellari1.png",
    "UntitledTurre2.png",
    "kellari1.png",
  ];

  // const nextImage = () => {
  //   setCurrentImage(currentImage + 1);

  //   if (currentImage === images.length - 1) {
  //     setCurrentImage(0);
  //   }
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (currentIndex === images.length - 1) {
  //       setCurrentIndex(0);
  //     } else {
  //       setCurrentIndex(currentIndex + 1);
  //     }
  //   }, 5000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [currentIndex]);

  return (
    <div className="galleria" id="galleria">
      <GalleriaBoksi className="galleria-tekstiboksi">
        {/* <MovingComponent
          type="fadeInFromLeft"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        > */}
        <h1>VARASTOCONCEPT</h1>
        {/* </MovingComponent> */}
        <div className="gallery-content">
          <p className="paragraph">
            VARASTO on veljesten järjestämä musiikkitapahtuma, joka sai alkunsa
            kun yhteiset kotibileet kasvoivat vuosi vuodelta suuremmiksi. Tämän
            seurauksena bileet tuli siirtää teollisuusvarastoon. Bileiden
            kasvaessa olemme pyrkineet säilyttämään kotibileistä tutun rennon
            tunnelman. Meille on tärkeää, että vieraat viihtyvät ja ilta jäisi
            mahdollisimman monelle mieleen ikimuistoisena. Ota siis bilefiilis-
            ja ystäväsi mukaan ja tule pitämään unohtumaton ilta VARASTO:lle!
          </p>
          <p>
            VARASTO on ystäviä, eurolavoittain kaljaa ja ennen kaikkea hyvää
            musiikkia.
          </p>
        </div>
      </GalleriaBoksi>
      <div className="galleria-kuva">
        <SideImage
          className="galleria-kuva-kuva"
          src={images[index]}
          alt="ohjelma"
        />
      </div>
    </div>
  );
};

export default Galleria;
