import "./App.css";
import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { SocialIcon } from "react-social-icons";
import { useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router } from "react-router-dom";
import Hero from "./components/Hero/Hero";
import Ohjelma from "./components/Ohjelma/Ohjelma";
import CountdownTimerComponent from "./components/CountdownTimer";
import Galleria from "./components/Galleria/Galleria";
import { Blackbar } from "./components/Blackbar";
import Info from "./components/Info/Info";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import { StyledMobileImage } from "./components/MobileImage/MobileImageElements";
import MobileImage from "./components/MobileImage/MobileImage";
import { useState } from "react";

function App() {
  const ref = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === 6 - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex]);


  return (
    <div className="App">
      <Router>
        <Navbar />
      </Router>
      <div>
        <Hero></Hero>
        <CountdownTimerComponent> </CountdownTimerComponent>
        {/* <div style={{ height: "100vh" }}></div> */}
        <Ohjelma index={currentIndex}></Ohjelma>
        <Blackbar></Blackbar>
        <StyledMobileImage>
          <MobileImage index={currentIndex}></MobileImage>
        </StyledMobileImage>
        <Galleria index={currentIndex}></Galleria>
        <Blackbar></Blackbar>
        <StyledMobileImage>
          <MobileImage index={currentIndex + 1}></MobileImage>
        </StyledMobileImage>
        <Info></Info>
        <Blackbar></Blackbar>
        <About></About>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
