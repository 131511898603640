import "./Hero.css";
import { useState } from "react";
//import MovingComponent from "react-moving-text";
import { CSSTransition } from "react-transition-group";
// import MovingText from "react-moving-text";
import styled from "styled-components";
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from "cdbreact";

const StyledHeaderImage = styled.img`
  width: 40%;
  margin-bottom: 10px;
`;


const Hero = () => {
  return (
    <section className="hero" id="hero">
      <video
        muted
        loop="loop"
        id="myVideo"
        autoPlay="autoplay"
        defaultMuted
        playsInline={true}
        onContextMenu={false}
        preload="auto"

      >
        <source src="varasto_club.mp4" type="video/mp4" />
      </video>
      <div className="instagram-logo">
        <CDBBtn
          flat
          color="dark"
          style={{marginLeft: "2rem"}}
          className="instagram"
          href="https://www.instagram.com/varastoconcept/?hl=fi"
          size={"2x"}
        >
          <CDBIcon fab icon="instagram" size={"2x"} />
        </CDBBtn>
      </div>
      <div className="hero-inner">
        {/* <MovingComponent
          type="fadeInFromLeft"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <h1>VARASTO2023</h1>
        </MovingComponent> */}
        {/* <MovingComponent
          type="flipSlowDown"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="5"
          fillMode="none"
        >
          <h1>
            VARASTO2023VARASTO2023VARASTO2023VARASTO2023VARASTO2023VARASTO2023
          </h1>
        </MovingComponent> */}
        {/* <h1 className="move-me2"> VARASTO2023</h1> */}
        {/* <StyledHeaderImage src="Logo.png"></StyledHeaderImage> */}
        {/* <MovingText
          type="fadeInFromLeft"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="infinite"
          fillMode="none"
        >
          <h1> VARASTO2023 </h1>
        </MovingText> */}
        {/* </MovingComponent>
        <MovingComponent
          type="fadeInFromRight"
          duration="1000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        > */}

        <h2 className="date">Vallila 28.10.2023</h2>
        {/* </MovingComponent> */}


      </div>
    </section>
  );
};

export default Hero;
