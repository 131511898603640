import styled from "styled-components";

export const SideImage = styled.img`
  width: 66.6vw;
  background-color: #110e10;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const GalleriaBoksi = styled.div`
  width: 33vw;
  background-color: #110e10;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 768px) {
    width: 100vw;
    padding-bottom: 15vw;
  }
`;
