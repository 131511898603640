import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import { Nav, NavLink, NavMenu, NavBtn, NavBtnLink } from "./NavBarElements";
import styled from "styled-components";

const StyledLogo = styled.img`
  width: 100px;
  height: 40px;
  filter: invert(100%);
`;

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [mouseOverLogo, setMouseOverLogo] = useState(false);
  const closeMenu = () => setClick(false);

  const handleTicketsClicked = () => {
    window.location.href = "https://kide.app/events/1dd4d5cc-54c9-4c03-8d07-f33552aed61b";
  };

  return (
    <>
      <Nav>
        <NavLink
          to="hero"
          smooth={true}
          offset={0}
          duration={500}
          onMouseOver={() => setMouseOverLogo(true)}
          onMouseLeave={() => setMouseOverLogo(false)}
        >
          <StyledLogo
            src={mouseOverLogo ? "Logo2.png" : "Logo2.png"}
            alt="logo"
            width={130}
            height={40}
          />
        </NavLink>
        <NavMenu>
          <NavLink
            to="ohjelma"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={closeMenu}
          >
            OHJELMA
          </NavLink>
          <NavLink
            to="galleria"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={closeMenu}
          >
            VARASTOCONCEPT
          </NavLink>
          <NavLink
            to="info"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={closeMenu}
          >
            INFO
          </NavLink>
          <NavLink
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={closeMenu}
          >
            GALLERIA
          </NavLink>
        </NavMenu>
        <NavBtn>
          <NavBtnLink to="" onClick={handleTicketsClicked}>
            Osta liput
          </NavBtnLink>
        </NavBtn>
      </Nav>
    </>
  );
};

export default Navbar;
