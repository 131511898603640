import "./Footer.css";
import React from "react";
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from "cdbreact";

const Footer = () => {
  return (
    <CDBFooter className="shadow">
      <CDBBox
        display="flex"
        flex="column"
        className="mx-auto py-5"
        style={{ width: "90%" }}
      >
        <CDBBox className="footer-content">
          <CDBBox className="box">
            <a href="/" className="d-flex align-items-center p-0 text-dark my-4">
              <img alt="logo" src="Logo.png" width="130px" className="logo" />
            </a>
            <p className="my-3" style={{ width: "250px" }}></p>
            <CDBBox display="flex" className="mt-4">
              {/* <CDBBtn flat color="dark">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn flat color="dark" className="mx-3">
                <CDBIcon fab icon="twitter" />
              </CDBBtn> */}
              <CDBBtn
                flat
                color="dark"
                className="instagram"
                href="https://www.instagram.com/varastoconcept/?hl=fi"
              >
                <CDBIcon fab icon="instagram" style={{color: "#fff"}}/>
              </CDBBtn>
            </CDBBox>
          </CDBBox>
          <CDBBox className="box">
            <h4 style={{ marginBottom: "2rem" }}>Sponsored by:</h4>
            <a href="https://www.nalla.fi/" className="nolla-logo">
              <img alt="logo" src="NollaLogo.jpg" width="150px" />
            </a>
          </CDBBox>
          <CDBBox className="box" >
            <p
              className="h5 mb-4"
              style={{ fontWeight: "600", marginTop: "1rem" }}
            >
              Contacts:
            </p>
            <p className="h5 mb-4" style={{ fontWeight: "400" }}>
              vuorenhelaj@gmail.com
            </p>
            <p className="h5 mb-4" style={{ fontWeight: "400" }}>
              +358458918489
            </p>
            <CDBBox flex="column" style={{ cursor: "pointer", padding: "0" }}>
              <a> </a>
            </CDBBox>
          </CDBBox>
        </CDBBox>
        <small className="madeBy">
          &copy; Leevi Kaunisto, 2023. All rights reserved.
        </small>
      </CDBBox>
    </CDBFooter>
  );
};

export default Footer;
