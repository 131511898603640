import styled from "styled-components";
import { Link as Link } from "react-scroll";
import { FaBars } from "react-icons/fa";

export const SideImage = styled.img`
  width: 66.6vw;
  background-color: #110e10;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const OhjelmaBoksi = styled.div`
  width: 33.3vw;
  background-color: #110e10;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 768px) {
    width: 100vw;
    padding-bottom: 15vw;
  }
  @media screen and (min-width: 2160px) {
    width: 100vw;
    padding-bottom: 15vw;
  }
`;

export const Aikataulut = styled.div`
  display: flex;
  flex-direction: column;
`;
